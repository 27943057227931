<template>
  <div>
    <el-row class='table-search'>
      <el-col :span='18'>
        <el-form :model='searchCondition' :inline='true' size='mini'>
          <el-form-item>
            <user-search :keyword.sync='keyword' @selected='handleSelectUser'></user-search>
            <!--            <user-dept-select @selected='handleSelected'></user-dept-select>-->
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model='searchCondition.year'
              type='year'
              format='yyyy年'
              value-format='yyyy'
              placeholder='选择年度' @change='changeQuarter' style='width: 140px'>
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <QuarterSelect size='mini' :quarter.sync='searchCondition.quarter'
                           @change='changeQuarter'></QuarterSelect>
          </el-form-item>
          <el-form-item>
            <el-button type='primary' @click='handleSearch' icon='el-icon-search'>查询</el-button>
          </el-form-item>
          <el-form-item>
            <export-link class-name='default-export-btn' lint-title='导出' ref='refExportLink'
                         :can-export='hasAuthority("pa_bonus_chief_export")>-1'
                         @export='exportExcel'></export-link>
          </el-form-item>
          <el-form-item>

            <LogPanel ref='refLogPanel' module-name='pa_chiefs' v-if='hasAuthority("pa_bonus_chief_log")'></LogPanel>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <div class='default-table'>
      <el-table
        :data='dataList'
        border
        style='width: 100%'>
        <el-table-column type='index' width='64' label='序号' align='center'>
        </el-table-column>
        <el-table-column prop='group_name' label='组别' min-width='280' align='center'>
        </el-table-column>
        <el-table-column prop='leader_name' label='姓名' min-width='100' align='center'></el-table-column>
        <el-table-column prop='quarter' label='季度' min-width='120' align='center'>
          <template slot-scope='{row}'>
            <span>{{ row.year || '' }}年第{{ row.quarter }}季度</span>
          </template>
        </el-table-column>
        <el-table-column prop='bonus_package' label='季度奖金包' min-width='120' align='center'></el-table-column>
        <el-table-column prop='payout_ratio' label='奖金发放比例' min-width='120' align='center'>
          <template slot-scope='{row}'>
            <span class='ratio'>{{ row.payout_ratio || '-' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop='remark' label='备注' min-width='220' align='center'
                         show-overflow-tooltip></el-table-column>
        <!--        <el-table-column prop='check_status' label='核算状态' min-width='120' align='center'>-->
        <!--          <template slot-scope='{row}'>-->
        <!--            <el-tag :type='row.check_status === "Y"?"success":"danger"' effect='plain'>-->
        <!--              {{ row.check_status === 'Y' ? '核算完成' : '待核算' }}-->
        <!--            </el-tag>-->
        <!--          </template>-->
        <!--        </el-table-column>-->
        <el-table-column prop='audit_status' label='状态' min-width='120' align='center'>
          <template slot-scope='{row}'>
            <el-tag :type='row.audit_status === "Y"?"success":"danger"' effect='plain'>
              {{ row.audit_status === 'Y' ? '已审核' : '待审核' }}
              {{ row.check_status === 'Y' ? '' : '（待核算）' }}
            </el-tag>
          </template>
        </el-table-column>
        <!--        <el-table-column label='创建时间' prop='created_at' width='120' align='center'-->
        <!--                         show-overflow-tooltip></el-table-column>-->
        <!--        <el-table-column label='修改时间' prop='updated_at' width='120' align='center'-->
        <!--                         show-overflow-tooltip></el-table-column>-->
        <el-table-column label='操作' min-width='160' align='center'
                         v-if='userPermissions.indexOf("pa_bonus_chief_audit")>-1'>
          <template slot-scope='{row}'>
            <div v-if='row.check_status === "Y"'>
              <el-link icon='el-icon-check' size='mini' type='success' @click='handleAudit(row)'
                       v-if="row.audit_status==='N'" title='审核【已核算完成的总监绩效】'>
                审&nbsp;&nbsp;核 <i v-if='loadingCommit' class='el-icon-loading'></i>
              </el-link>
              <el-link icon='el-icon-refresh-left' size='mini' type='warning' @click='handleCancel(row)'
                       v-if="row.audit_status==='Y'"> 撤销审核<i v-if='loadingCommit' class='el-icon-loading'></i>
              </el-link>
            </div>
          </template>
        </el-table-column>

      </el-table>

      <m-pagination :limit.sync='pageData.page_size' :total.sync='total' :page.sync='pageData.current_page'
                    @pagination='getList'></m-pagination>

    </div>
  </div>
</template>

<script>
import UserDeptSelect from '@/components/user/UserDeptSelect.vue'
import ExportBtn from '@/components/export/ExportBtn.vue'
import QuarterSelect from '@/pages/performance/components/QuarterSelect'
import { mapGetters } from 'vuex'
import UserSearch from '@/pages/user/components/UserSearch'
import ExportLink from '@/components/export/ExportLink'
import LogPanel from '@/components/logs/LogPanel'

export default {
  name: 'ChiefBonus',
  data() {
    return {
      loadingCommit: false,
      dataList: [],
      searchCondition: { quarter: null, year: null, leader_id: null },
      pageData: { page_size: 10, current_page: 1 },
      total: 0,
      initLoading: false,
      downLoading: false,
      keyword: null
    }
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  components: { LogPanel, ExportLink, UserSearch, QuarterSelect, ExportBtn, UserDeptSelect },

  methods: {
    defaultQuarter() {
      //当前季度
      this.searchCondition.year = (new Date()).getFullYear() + ''
      // 获取当前季度：
      this.searchCondition.quarter = this.$utils.getCurrentQuarter()
      this.handleSearch()
    },
    handleSearch() {
      this.pageData.current_page = 1
      this.getList()
    },
    async getList() {
      this.tableLoading = true
      //加载成员
      let searchCondition = this.handleSearchCondition()
      // this.dataList = []
      let { list, pages } = await this.$api.getChiefBonusList(searchCondition)
      this.dataList = list || []
      this.total = pages.total
      this.pageData.page_size = pages.page_size
      this.pageData.current_page = pages.current_page
    },
    async handleAudit(row) {
      this.$confirm('确认审核通过', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '再等等',
        type: 'warning'
      }).then(async () => {
        this.loadingCommit = true
        await this.$api.auditPaChiefStatus({ id: row.id, audit_status: 'Y' })
        this.loadingCommit = false
        await this.getList()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    async handleCancel(row) {
      this.$confirm('确认【撤销】审核', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '再等等',
        type: 'warning'
      }).then(async () => {
        this.loadingCommit = true
        await this.$api.auditPaChiefStatus({ id: row.id, audit_status: 'N' })
        this.loadingCommit = false
        await this.getList()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    handleSelectUser(val) {
      if (val)
        this.searchCondition.leader_id = val.id
      else
        this.searchCondition.leader_id = null

      this.handleSearch()
    },
    changeQuarter() {
      this.handleSearch()
    },
    handleSearchCondition() {
      let condition = {}
      Object.keys(this.searchCondition).forEach((field) => {
        if (this.searchCondition[field]) {
          condition[field] = this.searchCondition[field]
        }
      })


      // 分页 +排序
      Object.assign(condition, this.pageData, { orderBy: this.orderBy })

      return condition
    },
    handleSelected(data) {
      //直接添加成员列表
      console.log(data)
    },

    async exportExcel() {
      try {
        let searchCondition = this.handleSearchCondition()
        let response = await this.$api.exportChiefBonusList(searchCondition)
        let name = `总监奖金分配`

        this.$refs.refExportLink.exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        // alert(JSON.stringify(e))
      }
    }
  },
  mounted() {
    this.defaultQuarter()
    this.handleSearch()
  }
}
</script>

<style scoped>

</style>

<template>
  <div>
    <el-row class='default-table'>
      <el-col :span='24'>
        <div v-loading='loadingStatus'
             :element-loading-text='loadingText'
             element-loading-spinner='el-icon-loading'>
          <el-table
            :data='dataList'
            :span-method='objectSpanMethod'
            :summary-method='getSummaries'
            show-summary
            @row-contextmenu='openMenu'
            :cell-class-name='cellClassName'
            style='width: 100%;' border>
            <el-table-column
              prop='index'
              label='序号'
              align='center'
              width='60' fixed='fixed'>
              <template slot-scope='{$index}'>
                {{ $index + 1 }}
              </template>
            </el-table-column>
            <el-table-column
              prop='group_name'
              label='组别'
              align='center'
              min-width='120'>
              <template slot-scope='{row}'>
                <div>
                  <el-tooltip content='初次设置奖金' placement='top' effect='light'>
                    <div slot='content'>创建时间：{{ row.created_at }}
                      <br />修改时间：{{ row.updated_at }}
                      <br />审核状态： {{ statusObj.label || '' }}
                    </div>
                    <el-link type='primary' @click='editBonus(row)' v-if='editGroup'>{{ row.group_name }} <i
                      class='el-icon-edit'></i></el-link>
                    <span v-else>{{ row.group_name }}</span>
                    <span>{{ currentBonusBill.status || '' }}</span>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop='position'
              label='岗位'
              align='center'
              min-width='100'>
            </el-table-column>
            <el-table-column
              prop='name'
              label='姓名'
              align='center'
              min-width='100'>
              <template slot-scope='scope'>
                <span :title='`${scope.row.nickname}`'>{{ scope.row.name }}<span style='color: #E6A23C;margin-left: 5px'
                                                                                 v-if='scope.row.nickname&&scope.row.nickname!==scope.row.name'>({{
                    scope.row.nickname
                  }})</span></span>
              </template>
            </el-table-column>
            <el-table-column
              prop='trend_bonus'
              label='涨粉团队奖金'
              align='center'
              min-width='110'>
              <template slot-scope='scope'>
                <span class='money'>{{ valFormat(scope.row.trend_bonus * 1) }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop='revenue_bonus'
              label='营收团队奖金'
              align='center'
              min-width='110'>
              <template slot-scope='scope'>
                <span class='money'>{{ valFormat(scope.row.revenue_bonus * 1) }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop='total_bonus'
              label='总奖金'
              align='center'
              min-width='110'>
              <template slot-scope='scope'>
                <span class='money'>{{ valFormat(scope.row.trend_bonus * 1 + scope.row.revenue_bonus * 1) }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop='ratio'
              label='比例'
              align='center'
              min-width='100'>
              <editable-cell :show-input='row.editMode' slot-scope='{row,column}'
                             v-model='row[column.property]'
                             @change="editVal(row,'ratio')" :is-input-number='true'
                             v-if="userPermissions.indexOf('bonus_save') > -1&&editMode">
                          <span slot='content'>
                            <span class='ratio'>{{ row[column.property] }}</span>
                            <i class='el-icon-edit default-edit-icon' v-if='showEditIcon'></i>
                          </span>
              </editable-cell>
              <template slot-scope='{row,column}' v-else>
                <span class='ratio'>{{ row[column.property] }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop='amount'
              label='奖金'
              align='center'
              min-width='100'>
              <template slot-scope='{row}'>
              <span class='money'>
                {{ valFormat((row.ratio * row.total_bonus) / 100) }}
              </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <!--    右键菜单-修改红人考核月份-->
    <Contextmenu :menu-data='menuData' @change='changeCheck' />
  </div>
</template>

<script>
import EditableCell from '@/components/EditableCell'
import { numberFormat } from '@/utils/utils'
import { mapGetters } from 'vuex'
import Contextmenu from './Contextmenu'

export default {
  name: 'BonusTable',
  components: {
    EditableCell, Contextmenu
  },
  computed: {
    ...mapGetters(['userPermissions']),
    loadingText() {
      return `奖金明细数据加载中...`
    },
    // 表格数据
    dataList() {
      let tableData = []
      if (this.billItems && this.billItems.length > 0) {
        this.billItems.forEach((item) => {
          item.revenue_bonus = this.bonus_bill.revenue_bonus / 1
          item.trend_bonus = this.bonus_bill.trend_bonus / 1
          item.total_bonus = this.bonus_bill.revenue_bonus / 1 + this.bonus_bill.trend_bonus / 1
          item.group_id = this.bonus_bill.group_id
          item.group_name = this.bonus_bill.group_name
          tableData.push(item)
        })
      }
      return tableData
    },
    statusObj() {
      const obj = this.statusOptions.find(value => value.value == this.currentBonusBill.status)

      return obj
    }
  },
  props: {
    currentBonusBill: {
      type: Object,
      default() {
        return {}
      }
    },
    searchCondition: {
      type: Object,
      default() {
        return {
          id: null
        }
      }
    },
    editMode: {
      type: Boolean,
      default() {
        return false
      }
    },
    editGroup: {
      type: Boolean,
      default() {
        return false
      }
    },
    canRevoke: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  watch: {
    searchCondition: {
      deep: true,
      handler() {
        // console.log('val',val)
        this.getInfo()
      }
    }
  },
  data() {
    return {
      loadingStatus: false,
      unsaved: false,
      showEditIcon: true,
      billItems: [],
      // dataList: [],
      bonus_bill: {},
      mergeFields: ['group_name', 'trend_bonus', 'revenue_bonus', 'total_bonus'],//合并的字段
      spanObj: {},//合并的数据坐标Map
      positionObj: {},//合并的位置记录
      //start:鼠标右键菜单
      menuData: {
        visible: false,
        top: 0,
        left: 0
      },
      currentRow: {},
      currentColumn: {},
      statusOptions: [
        { label: '待提审', value: 0 },
        { label: '总监审核中', value: 1 },
        { label: '人资审核中', value: 2 },
        { label: '审核完毕', value: 3 }
      ]
    }
  },
  methods: {
    getStatusAlias(val) {
      const obj = this.statusOptions.find(value => value.value == val)
      console.log('obj', val, obj)
      return obj
    },
    // 表格合并行
    // eslint-disable-next-line
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      let len = this.dataList.length
      const fields = this.mergeFields
      if (fields.indexOf(column.property) > -1) {
        if (rowIndex % len === 0) {
          return {
            rowspan: len,
            colspan: 1
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 2) {
          sums[index] = '合计'
          return
        }
        if (['ratio', 'amount'].indexOf(column.property) > -1) {
          const values = data.map(item => Number(item[column.property]))
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev * 1 + curr * 1
              } else {
                return prev
              }
            }, 0)
            sums[index] += ''
          } else {
            sums[index] = 'N/A'
          }
          // 格式化数据
          switch (column.property) {
            case 'ratio':
              sums[index] = `${(sums[index])} %`
              break
            case 'amount':
              sums[index] = `￥${this.valFormat(sums[index])}`
              break
            default:

              break
          }
        }
      })

      return sums
    },
    valFormat(value) {
      return numberFormat(value, 2, '.', ',', 'round')
    },
    editVal(row, colName) {
      if (colName === 'ratio') {
        row.amount = (row.trend_bonus * 1 + row.revenue_bonus * 1) * row.ratio / 100
      }
      this.unsaved = true
      this.$emit('editVal', this.dataList)// 通知父组件 修改后的表格数据
    },
    handleSearchCondition() {
      let condition = {}
      this.conditionStr = ''
      if (this.searchCondition.id) {
        condition.id = this.searchCondition.id
      }

      return condition
    },

    async getInfo() {
      this.loadingStatus = true
      this.billItems = []
      this.bonus_bill = {}
      let searchCondition = this.handleSearchCondition()
      if (searchCondition.id) {
        // let {info, items} = await this.$api.getPerformanceBonusInfo(searchCondition)
        let { info, items } = await this.$api.getPaGroupBonusInfo(searchCondition)

        this.bonus_bill = info
        this.billItems = items
        // console.log('info', info)
        this.$emit('getInfo', this.bonus_bill)

      }
      this.loadingStatus = false

    },
    editBonus(row) {
      this.$emit('editBonus', row)
    },
    revokeBtn(row) {
      this.$confirm(`此操作将撤回[${row.group_name}/ ${this.searchCondition.ym}]的审核状态至[待总监审核], 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$emit('revokeEvent', { group_id: row.group_id, ym: this.searchCondition.ym })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })

    },
    //修改成员分配信息是否显示问题
    async changeCheck(val) {
      if (['Y', 'N'].indexOf(val) > -1) {
        this.currentRow.is_show = val
        //标记隐藏
        await this.$api.changePaGroupBonusShowStatus({ id: this.currentRow.id, is_show: val })
      } else {
        //删除
        let info = await this.$api.delPaGroupBonusMember(this.currentRow.id)
        if (info) {
          this.$notify.success('删除成功')
          let index = this.dataList.findIndex(value => value.id === this.currentRow.id)
          if (index > -1) {
            this.dataList.splice(index, 1)
          }
        }
      }
    },
    //右键菜单：设置是否月考核
    closeMenu(e) {
      //未点击在 菜单上，则关闭
      if (e.target.className.indexOf('contextmenu__item') === -1) {
        this.menuData.visible = false
        document.removeEventListener('click', this.closeMenu)
      }
    },
    //表格事件：行右键
    openMenu(row, column, e) {
      this.currentRow = row
      this.currentColumn = column
      e.preventDefault()//阻止元素发生默认的行为
      const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
      const offsetTop = this.$el.getBoundingClientRect().top // container margin left
      this.menuData = {
        visible: true,
        left: e.clientX - offsetLeft + 20,
        top: e.clientY - offsetTop + 20
      }
      document.addEventListener('click', this.closeMenu) // 给整个document新增监听鼠标事件，点击任何位置执行foo方法

    },
    //单元格样式调整
    // eslint-disable-next-line
    cellClassName({ row, column, rowIndex, columnIndex }) {
      //判定是否标记隐藏：
      if (column.property === 'name' && row.is_show === 'N') {
        return 'cell-uncheck'
      }
      return ''
    }
  }
}
</script>

<style scoped>
.bill_no {
  color: #fff;
  opacity: 0.5;
  margin-right: 10px;
}

</style>
